// Safari は未対応のため、代わりに setTimeout を使用する
// SEE: https://developer.mozilla.org/ja/docs/Web/API/Window/requestIdleCallback
window.requestIdleCallback =
  window.requestIdleCallback ??
  ((cb) => {
    return setTimeout(() => {
      const start = Date.now()
      cb({
        didTimeout: false,
        timeRemaining: () => {
          return Math.max(0, 50 - (Date.now() - start))
        },
      })
    }, 1)
  })

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webkit: any
  }
}

// NOTE: iOSのwebviewではwindow.confirmが正常に動作しない為
export const workaroundIosConfirm = (): void => {
  // SEE: https://stackoverflow.com/q/28795476
  if (navigator.platform.slice(0, 2) === 'iP' && window.webkit && window.webkit.messageHandlers) {
    window.confirm = () => true
  }
}

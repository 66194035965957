import { user } from './rails'

const PROMPT_OPTIONS = {
  actionMessage: 'プッシュ通知を受け取りたい場合には、ブラウザの設定変更の許可を与えてください。',
  acceptButtonText: '通知を有効にする',
  cancelButtonText: '閉じる',
  customlink: {
    enabled: true,
    style: 'button',
    size: 'medium',
    text: {
      subscribe: 'プッシュ通知を受信する',
      unsubscribe: 'プッシュ通知を解除する',
      explanation: 'プッシュ通知を有効にすることで、最新のお知らせにすぐに気付くことができます。',
    },
    unsubscribeEnabled: true,
  },
}

export const DISABLE_PERSIST_NOTIFICATION_KEY = 'disablePersistNotification'

const isPersistNotification = (): boolean => {
  try {
    const value = localStorage.getItem(DISABLE_PERSIST_NOTIFICATION_KEY)
    if (!value) return true

    return value === 'false'
  } catch {
    return true
  }
}

export const initOneSignal = (): void => {
  if (import.meta.env.RAILS_ENV === 'test' || !window.N2JK.rails.onesignalAppId || window.location.protocol === 'http:')
    return

  const script = document.createElement('script')
  script.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js'
  script.defer = true
  document.head.append(script)

  window.OneSignalDeferred = window.OneSignalDeferred || []

  // SEE: https://documentation.onesignal.com/docs/web-sdk-reference
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.OneSignalDeferred.push(async (OneSignal: any) => {
    await OneSignal.init({
      appId: window.N2JK.rails.onesignalAppId,
      autoPrompt: false,
      promptOptions: PROMPT_OPTIONS,
      persistNotification: isPersistNotification(),
      notifyButton: {
        enable: true,
        displayPredicate: () => false,
      },
    })

    const currentUser = user()

    if (currentUser) {
      OneSignal.Slidedown.promptPush()
    }

    if (currentUser) {
      OneSignal.User.addTag('onesignal_token', currentUser.onesignalToken)
    } else {
      OneSignal.User.removeTag('onesignal_token')
    }
  })
}

import { Controller } from '@hotwired/stimulus'
// @ts-expect-error 型定義がないので仕方なく
import { patch } from '@rails/request.js'
// eslint-disable-next-line import/no-named-as-default
import Sortable from 'sortablejs'

export class SortableController extends Controller<HTMLElement> {
  declare resourceNameValue: string
  declare sortable?: Sortable

  static values = {
    resourceName: String,
  }

  connect = () => {
    this.sortable = new Sortable(this.element, {
      animation: 300,
      handle: '.handle',
      onUpdate: ({ item, newIndex }) => {
        if (newIndex === undefined) return

        const updateUrl = item.dataset.sortableUpdateUrl
        if (!updateUrl) return

        const body = new FormData()
        body.append(`${this.resourceNameValue}[row_order_position]`, newIndex.toString())
        return patch(updateUrl, { body })
      },
    })
  }

  disconnect = () => {
    this.sortable?.destroy()
    this.sortable = undefined
  }
}

import { useComposing } from '@/src/hooks/useComposing'

export type OnKeydown = (event: KeyboardEvent) => void

type Navigation = 'prev' | 'next' | 'select'

export type Handler = (event: KeyboardEvent) => void

type UseKeyboardScrollNavigationOptions = {
  prev?: Handler
  next?: Handler
  select?: Handler
}

type UseKeyboardScrollNavigationReturn = {
  onKeydown: OnKeydown
}

export const useKeyboardScrollNavigation = (
  options: UseKeyboardScrollNavigationOptions,
): UseKeyboardScrollNavigationReturn => {
  const isComposing = useComposing()

  const handleNavigation = (navigation: Navigation, event: KeyboardEvent) => {
    const handler = options[navigation]
    if (!handler) return

    handler(event)
    event.preventDefault()
  }

  const onKeydown: OnKeydown = (event) => {
    const { key, ctrlKey } = event

    if (isComposing.value) return

    if (key === 'ArrowUp' || (ctrlKey && key === 'p')) {
      handleNavigation('prev', event)
    } else if (key === 'ArrowDown' || (ctrlKey && key === 'n')) {
      handleNavigation('next', event)
    } else if (key === 'Enter') {
      handleNavigation('select', event)
    }
  }

  return { onKeydown }
}

<script lang="ts">
import { gql } from '@apollo/client/core'
import type { TaskForSearchModalFragment } from '@/src/graphql/generated'

gql`
  fragment taskForSearchModal on SearchTaskResultEdge {
    highlightHtml
    node {
      id
      createdAt
      title
      url
      status
      statusText
      registeredUser {
        id
        imageThumbUrl
        name
      }
    }
  }
`
</script>

<script lang="ts" setup>
defineProps<{
  task: TaskForSearchModalFragment
}>()
</script>

<template>
  <div class="d-flex gap-2">
    <UserIcon :name="task.node.registeredUser.name" :url="task.node.registeredUser.imageThumbUrl" />
    <div class="vstack gap-1">
      <div class="vstack">
        <div class="hstack gap-3">
          <local-time :datetime="task.node.createdAt" class="text-body-secondary" />
          <TaskStatusBadge :status="task.node.status" :status-text="task.node.statusText" />
        </div>
        <span class="tw-text-sm fw-bold">{{ task.node.title }}</span>
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="text-body-secondary" v-html="task.highlightHtml"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { gql } from '@apollo/client/core'
import { useMutation } from '@vue/apollo-composable'
import type { Project } from '@/src/components/ProjectsMenu.vue'
import type { OnClick } from '@/src/components/TogglePinnedProjectButton.vue'
import { UpdatePinnedProjectDocument } from '@/src/graphql/generated'

gql`
  mutation updatePinnedProject($projectId: ID!, $pinned: Boolean!) {
    updatePinnedProject(input: { projectId: $projectId, pinned: $pinned }) {
      membership {
        id
        pinned
      }
    }
  }
`

type useUpdatePinnedProjectOptions = {
  projectId: string
  pinned: boolean
}

const useUpdatePinnedProject = () => {
  const { mutate } = useMutation(UpdatePinnedProjectDocument)

  const updatePinnedProject = async ({ projectId, pinned }: useUpdatePinnedProjectOptions) => {
    await mutate({
      projectId,
      pinned,
    })
  }

  return updatePinnedProject
}
</script>

<script lang="ts" setup>
const props = defineProps<{
  project: Project
}>()

const updatePinnedProject = useUpdatePinnedProject()

const handleClickPinIcon: OnClick = () => {
  updatePinnedProject({ projectId: props.project.id, pinned: !props.project.pinned })
}
</script>

<template>
  <TogglePinnedProjectButton :pinned="props.project.pinned" :on-click="handleClickPinIcon" />
</template>

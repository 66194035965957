import { isUserSignedIn } from './rails'

export const initAppInstallBanners = (): void => {
  // NOTE: 未ログインの場合はアプリのインストール バナー表示を先送り
  window.addEventListener('beforeinstallprompt', (event) => {
    if (!isUserSignedIn()) {
      event.preventDefault()
      return false
    }
    return true
  })
}

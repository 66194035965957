<script lang="ts" setup>
import type { SearchResultItem } from '@/src/components/SearchModal.vue'
import { isDownloadable } from '@/src/lib/blobUtils.js'

const props = defineProps<{
  item: SearchResultItem
  active: boolean
}>()

const target = computed(() => {
  if (props.item.node.__typename !== 'Attachment') return

  const { blob } = props.item.node
  return isDownloadable(blob) ? undefined : '_blank'
})

const download = computed(() => {
  if (props.item.node.__typename !== 'Attachment') return

  const { blob } = props.item.node
  return isDownloadable(blob) ? blob.filename : undefined
})
</script>

<template>
  <ScrollLIstGroupItem :href="item.node.url" :active="active" :target="target" :download="download">
    <slot></slot>
  </ScrollLIstGroupItem>
</template>

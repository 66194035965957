<script lang="ts" setup>
import { addCollection } from 'iconify-icon'
import icons8 from '@/src/vendor/icons8/icons8-color.json'

addCollection(icons8)

const ICON_MAPPING: Record<string, string> = {
  'application/pdf': 'icons8-color:pdf',
  'application/msword': 'icons8-color:microsoft-word-2019',
  'application/vnd.ms-word': 'icons8-color:microsoft-word-2019',
  'application/vnd.oasis.opendocument.text': 'icons8-color:microsoft-word-2019',
  'application/vnd.openxmlformats-officedocument.wordprocessingml': 'icons8-color:microsoft-word-2019',
  'application/vnd.ms-excel': 'icons8-color:microsoft-excel-2019',
  'application/vnd.openxmlformats-officedocument.spreadsheetml': 'icons8-color:microsoft-excel-2019',
  'application/vnd.oasis.opendocument.spreadsheet': 'icons8-color:microsoft-excel-2019',
  'application/vnd.ms-powerpoint': 'icons8-color:microsoft-powerpoint-2019',
  'application/vnd.openxmlformats-officedocument.presentationml': 'icons8-color:microsoft-powerpoint-2019',
  'application/vnd.oasis.opendocument.presentation': 'icons8-color:microsoft-powerpoint-2019',
  'text/csv': 'icons8-color:csv',
  'text/plain': 'icons8-color:txt',
  'text/html': 'icons8-color:html-5',
  'application/json': 'icons8-color:json',
  'application/gzip': 'icons8-color:archive',
  'application/zip': 'icons8-color:archive',
  image: 'icons8-color:image',
  audio: 'icons8-color:audio',
  video: 'icons8-color:video',
  text: 'icons8-color:txt',
}

const props = defineProps<{
  contentType: string
}>()

const icon = computed<string>(() => {
  const candidate = Object.entries(ICON_MAPPING).find(([mime]) => props.contentType.startsWith(mime))
  return candidate ? candidate[1] : 'icons8-color:file'
})
</script>

<template>
  <span>
    <iconify-icon :icon="icon" inline />
  </span>
</template>

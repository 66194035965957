<script lang="ts">
import { gql } from '@apollo/client/core'
import { useBoolean } from '@sonicgarden/vueuse'
import { provideApolloClient, useQuery } from '@vue/apollo-composable'
import { useDebounce, useSessionStorage } from '@vueuse/core'
import type { Ref } from 'vue'
import type { NavItem } from '@/src/components/SearchModal.vue'
import { SearchAttachmentsDocument, SearchMessagesDocument, SearchTasksDocument } from '@/src/graphql/generated'
import { onKeyStrokeWithoutInput } from '@/src/hooks/onKeyStrokeWithoutInput'
import { getApolloClient } from '@/src/lib/apollo'
import { assertUnreachable } from '@/src/lib/typeUtils'

gql`
  query searchTasks($projectId: ID!, $query: String!) {
    project(id: $projectId) {
      id
      searchTasks(query: $query, first: 50) {
        edges {
          ...taskForSearchModal
        }
        pageInfo {
          hasNextPage
        }
        totalCount
      }
    }
  }

  query searchMessages($projectId: ID!, $query: String!) {
    project(id: $projectId) {
      id
      searchMessages(query: $query, first: 50) {
        edges {
          ...messageForSearchModal
        }
        pageInfo {
          hasNextPage
        }
        totalCount
      }
    }
  }

  query searchAttachments($projectId: ID!, $query: String!) {
    project(id: $projectId) {
      id
      searchAttachments(query: $query, first: 50) {
        edges {
          ...attachmentForSearchModal
        }
        pageInfo {
          hasNextPage
        }
        totalCount
      }
    }
  }
`

const MIN_QUERY_LENGTH = 2

type UseSearchQueryOptions = {
  projectId: Ref<string>
  query: Ref<string>
  enabled: Ref<boolean>
}

const useSearchTasks = ({ projectId, query, enabled }: UseSearchQueryOptions) => {
  const searchable = computed(() => query.value.length >= MIN_QUERY_LENGTH)
  const { result, loading } = useQuery(
    SearchTasksDocument,
    () => ({
      projectId: projectId.value,
      query: query.value,
    }),
    () => ({
      enabled: enabled.value && searchable.value,
      notifyOnNetworkStatusChange: true,
    }),
  )
  const results = computed(() => result.value?.project?.searchTasks.edges ?? [])
  const moreResults = computed(() => result.value?.project?.searchTasks.pageInfo.hasNextPage ?? false)
  const totalCount = computed(() => result.value?.project?.searchTasks.totalCount ?? 0)

  // NOTE: refの入れ子を避けるためにこうしているがイマイチな作りな気がするので、いい改善案思いついたら見直したい
  return computed(() => ({
    results: unref(results),
    moreResults: unref(moreResults),
    totalCount: unref(totalCount),
    loading: unref(loading),
    searchable: unref(searchable),
  }))
}

const useSearchMessages = ({ projectId, query, enabled }: UseSearchQueryOptions) => {
  const searchable = computed(() => query.value.length >= MIN_QUERY_LENGTH)
  const { result, loading } = useQuery(
    SearchMessagesDocument,
    () => ({
      projectId: projectId.value,
      query: query.value,
    }),
    () => ({
      enabled: enabled.value && searchable.value,
    }),
  )
  const results = computed(() => result.value?.project?.searchMessages.edges ?? [])
  const moreResults = computed(() => result.value?.project?.searchMessages.pageInfo.hasNextPage ?? false)
  const totalCount = computed(() => result.value?.project?.searchMessages.totalCount ?? 0)

  return computed(() => ({
    results: unref(results),
    moreResults: unref(moreResults),
    totalCount: unref(totalCount),
    loading: unref(loading),
    searchable: unref(searchable),
  }))
}

const useSearchAttachments = ({ projectId, query, enabled }: UseSearchQueryOptions) => {
  const searchable = computed(() => query.value.length >= MIN_QUERY_LENGTH)
  const { result, loading } = useQuery(
    SearchAttachmentsDocument,
    () => ({
      projectId: projectId.value,
      query: query.value,
    }),
    () => ({
      enabled: enabled.value && searchable.value,
    }),
  )
  const results = computed(() => result.value?.project?.searchAttachments.edges ?? [])
  const moreResults = computed(() => result.value?.project?.searchAttachments.pageInfo.hasNextPage ?? false)
  const totalCount = computed(() => result.value?.project?.searchAttachments.totalCount ?? 0)

  return computed(() => ({
    results: unref(results),
    moreResults: unref(moreResults),
    totalCount: unref(totalCount),
    loading: unref(loading),
    searchable: unref(searchable),
  }))
}
</script>

<script lang="ts" setup>
const props = defineProps<{
  projectId: string
}>()

provideApolloClient(getApolloClient())

// NOTE: props.projectIdが変化することはありえない
const query = useSessionStorage(`search-query-${props.projectId}`, '')
const activeNavItem = useSessionStorage<NavItem>(`search-active-nav-item-${props.projectId}`, 'message')

const debouncedQuery = useDebounce(query, 500)
// NOTE: 空白を追加しただけで無駄なリクエストが走ったりしないようにtrimしている
const trimmedQuery = computed(() => debouncedQuery.value.trim())

// NOTE: 非アクティブのタブ用のクエリまで同時に検索走って体感速度落ちないように
const inactiveQuery = useDebounce(trimmedQuery, 1000)

const messageQuery = computed(() => (activeNavItem.value === 'message' ? trimmedQuery.value : inactiveQuery.value))
const taskQuery = computed(() => (activeNavItem.value === 'task' ? trimmedQuery.value : inactiveQuery.value))
const attachmentQuery = computed(() =>
  activeNavItem.value === 'attachment' ? trimmedQuery.value : inactiveQuery.value,
)

const [visible, setVisible] = useBoolean(false)

onKeyStrokeWithoutInput('/', () => setVisible.on())

const taskResult = useSearchTasks({
  projectId: toRef(props, 'projectId'),
  query: taskQuery,
  enabled: visible,
})

const messageResult = useSearchMessages({
  projectId: toRef(props, 'projectId'),
  query: messageQuery,
  enabled: visible,
})

const attachmentResult = useSearchAttachments({
  projectId: toRef(props, 'projectId'),
  query: attachmentQuery,
  enabled: visible,
})

const searchResult = computed(() => {
  switch (activeNavItem.value) {
    case 'task': {
      return taskResult.value
    }
    case 'message': {
      return messageResult.value
    }
    case 'attachment': {
      return attachmentResult.value
    }
    default: {
      assertUnreachable(activeNavItem.value)
    }
  }
})

const taskResultCount = computed(() => taskResult.value.totalCount)
const messageResultCount = computed(() => messageResult.value.totalCount)
const attachmentResultCount = computed(() => attachmentResult.value.totalCount)
</script>

<template>
  <!-- eslint-disable vue/no-v-model-argument -->
  <div>
    <bs-tooltip content="プロジェクト内を検索（/）">
      <BsButton variant="link" class="text-dark text-decoration-none" @click="setVisible.on">
        <iconify-icon icon="bi:search" inline />
        <span class="ms-1 d-none d-md-inline">検索</span>
      </BsButton>
    </bs-tooltip>
    <SearchModal
      v-model:query="query"
      v-model:visible="visible"
      v-model:active-nav-item="activeNavItem"
      :results="searchResult.results"
      :searchable="searchResult.searchable"
      :loading="searchResult.loading"
      :more-results="searchResult.moreResults"
      :task-result-count="taskResultCount"
      :message-result-count="messageResultCount"
      :attachment-result-count="attachmentResultCount"
    />
  </div>
</template>

import { get, parseRequestOptionsFromJSON, supported } from '@github/webauthn-json/browser-ponyfill'

export class PasskeyLogin extends HTMLElement {
  static formAssociated = true

  private _internals: ElementInternals

  constructor() {
    super()
    this._internals = this.attachInternals()
    this.hidden = true
  }

  connectedCallback(): void {
    console.debug(supported())
    if (!supported()) {
      if (this._internals.form) {
        this._internals.form.hidden = true
      }
      return
    }
    this._internals.form?.addEventListener('submit', this.handleSubmit)
  }

  disconnectedCallback(): void {
    this._internals.form?.removeEventListener('submit', this.handleSubmit)
  }

  private handleSubmit = async (event: SubmitEvent) => {
    event.preventDefault()

    try {
      const credential = await get(this.options)
      console.debug('Passkey credential:', credential)

      this._internals.setFormValue(JSON.stringify(credential))
      this._internals.form?.submit()
    } catch (error) {
      console.debug('Passkey error:', error)
    }
  }

  get options() {
    const value = this.getAttribute('options')
    if (!value) throw new Error('options attribute is missing')

    const publicKey = JSON.parse(value)
    return parseRequestOptionsFromJSON({ publicKey })
  }
}

declare global {
  interface Window {
    PasskeyLogin: typeof PasskeyLogin
  }
}

if (!window.customElements.get('passkey-login')) {
  window.PasskeyLogin = PasskeyLogin
  window.customElements.define('passkey-login', PasskeyLogin)
}

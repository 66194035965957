import { gql } from '@apollo/client/core'
import type { StrategyProps } from '@textcomplete/core'
import type { UserForTextcompleteFragment } from '@/src/graphql/generated'
import { ProjectUsersDocument } from '@/src/graphql/generated'
import { getApolloClient } from './apollo'
import { sortUsersByPriorityAndName } from './sortUsers'

gql`
  fragment userForTextcomplete on User {
    id
    name
    email
    lastSignInAt
  }

  query ProjectUsers($id: ID!) {
    project(id: $id) {
      id
      availableUsers {
        ...userForTextcomplete
      }
    }
  }
`

type MentionItem = {
  name: string
  email: string
  lowPriority?: boolean
}

// NOTE: vueコンポーネント移行までの暫定対応
const getProjectUsers = async (): Promise<readonly UserForTextcompleteFragment[]> => {
  const projectId = window.N2JK.rails.currentProjectId
  if (!projectId) return []

  const { data } = await getApolloClient().query({
    query: ProjectUsersDocument,
    variables: {
      id: projectId,
    },
  })

  return data?.project?.availableUsers ?? []
}

const escapeHTML = (text: string): string => {
  const div = document.createElement('div')
  div.textContent = text
  return div.innerHTML
}

const normalizeMentionName = (name: string): string => name.replaceAll(/(^\s+)|(\s+$|@)/g, '')

let cachedMentionItems: readonly MentionItem[]

const getMentionItems = async (): Promise<typeof cachedMentionItems> => {
  if (cachedMentionItems) return cachedMentionItems

  const users = sortUsersByPriorityAndName(await getProjectUsers())
  const normalizedMentionItems = users.map(({ name, email, lowPriority }) => ({
    email,
    lowPriority,
    name: normalizeMentionName(name),
  }))

  // eslint-disable-next-line require-atomic-updates
  cachedMentionItems = [{ name: 'all', email: '' }, ...normalizedMentionItems]
  return cachedMentionItems
}

export const mentionStrategy: StrategyProps<MentionItem> = {
  id: 'mention',
  match: /(^|\s)@([\d.A-Za-zぁ-ヶー一-鿆０-９Ａ-Ｚａ-ｚｦ-ﾟ-]*)$/,
  async search(term, callback) {
    const loweredTerm = term.toLowerCase()
    const items = await getMentionItems()
    return callback(
      items.filter((user) => {
        return [user.name, user.email].some((value) => value.toLowerCase().includes(loweredTerm))
      }),
    )
  },
  template(user) {
    const email = escapeHTML(user.email)
    const name = escapeHTML(user.name)
    const className = user.lowPriority ? 'is-low-priority' : ''
    // eslint-disable-next-line github/unescaped-html-literal
    return email ? `<span class="${className}">${name} <small>(${email})</small></span>` : name
  },
  replace(user) {
    return `$1@${escapeHTML(user.name)} `
  },
  index: 2,
}

export const emojiStrategy: StrategyProps<[string, string]> = {
  id: 'emoji',
  match: /(^|\s):([\d+_a-z-]*)$/,
  async search(term, callback) {
    const { EMOJI_DATA } = await import('./emojiData')
    const results = Object.entries(EMOJI_DATA).filter(([name]) => name.startsWith(term))
    callback(results)
  },
  template([name, emoji]) {
    return `${emoji} ${name}`
  },
  replace([, emoji]) {
    return `$1${emoji}`
  },
  index: 2,
}

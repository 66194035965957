<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import type { NavItem } from '@/src/components/SearchModal.vue'

const props = defineProps<{
  modelValue: NavItem
  showCount: boolean
  messageResultCount: number
  taskResultCount: number
  attachmentResultCount: number
}>()

const emit = defineEmits<{
  'update:modelValue': [activeNavItem: NavItem]
}>()

const modelActiveNavItem = useVModel(props, 'modelValue', emit, {
  passive: true,
})
</script>

<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <button
        class="nav-link"
        :class="{ active: modelActiveNavItem === 'message' }"
        @click.prevent="modelActiveNavItem = 'message'"
      >
        掲示板
        <BsBadge v-show="showCount" variant="secondary" pill>{{ messageResultCount }}</BsBadge>
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        :class="{ active: modelActiveNavItem === 'task' }"
        @click.prevent="modelActiveNavItem = 'task'"
      >
        タスク
        <BsBadge v-show="showCount" variant="secondary" pill>{{ taskResultCount }}</BsBadge>
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        :class="{ active: modelActiveNavItem === 'attachment' }"
        @click.prevent="modelActiveNavItem = 'attachment'"
      >
        添付ファイル
        <BsBadge v-show="showCount" variant="secondary" pill>{{ attachmentResultCount }}</BsBadge>
      </button>
    </li>
  </ul>
</template>

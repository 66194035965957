import { bugsnagTest } from '@/src/lib/bugsnagTest'
import { setupWebVitals } from '@/src/lib/webVitals'
import { initAppInstallBanners } from './appInstallBanners'
import './textcomplete'
import { initNotify } from './notify'
import { initOneSignal } from './onesignal'
import { addWebpushClassName } from './webpush'
import { workaroundIosConfirm } from './workaroundIosConfirm'

export const initLib = (): void => {
  initOneSignal()
  initAppInstallBanners()
  addWebpushClassName()
  initNotify()
  workaroundIosConfirm()
  setupWebVitals()

  if (import.meta.env.RAILS_ENV === 'staging') {
    bugsnagTest()
  }
}

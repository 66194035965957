import { create, parseCreationOptionsFromJSON } from '@github/webauthn-json/browser-ponyfill'

export class PasskeyRegistration extends HTMLElement {
  static formAssociated = true

  private _internals: ElementInternals

  constructor() {
    super()
    this._internals = this.attachInternals()
    this.hidden = true
  }

  connectedCallback(): void {
    this._internals.form?.addEventListener('submit', this.handleSubmit)
  }

  disconnectedCallback(): void {
    this._internals.form?.removeEventListener('submit', this.handleSubmit)
  }

  private handleSubmit = async (event: SubmitEvent) => {
    event.preventDefault()

    try {
      const credential = await create(this.options)
      console.debug('Passkey options:', credential)

      this._internals.setFormValue(JSON.stringify(credential))
      this._internals.form?.submit()
    } catch (error) {
      console.debug('Passkey error:', error)
    }
  }

  get options() {
    const value = this.getAttribute('options')
    if (!value) throw new Error('options attribute is missing')

    const publicKey = JSON.parse(value)
    return parseCreationOptionsFromJSON({ publicKey })
  }
}

declare global {
  interface Window {
    PasskeyRegistration: typeof PasskeyRegistration
  }
}

if (!window.customElements.get('passkey-registration')) {
  window.PasskeyRegistration = PasskeyRegistration
  window.customElements.define('passkey-registration', PasskeyRegistration)
}

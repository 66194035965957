// TODO: onFID は deprecated になっているため代替案を探すか必要性を検討する
import type { MetricType } from 'web-vitals'
import { onLCP, onFID, onCLS } from 'web-vitals'

const DEBUG_QUERY_STRING = 'debug-web-vitals'

const report = (metric: MetricType) => {
  if (window.location.search.includes(DEBUG_QUERY_STRING)) {
    // eslint-disable-next-line no-console
    console.log(metric)
  }

  if (window.gtag) {
    const { name, delta, id } = metric
    const value = Math.round(name === 'CLS' ? delta * 1000 : delta)
    // SEE: https://github.com/GoogleChrome/web-vitals#using-gtagjs-google-analytics-4
    window.gtag('event', name, {
      value,
      metric_id: id,
      metric_value: value,
      metric_delta: delta,
    })
  }

  // NOTE: bugsnag側で1時間に10回以上発生したら通知するようにして様子見中
  if (metric.name === 'FID' && metric.rating === 'poor') {
    window.bugsnagClient?.notify(
      { errorClass: 'WebVitalsWarning', errorMessage: `Poor FID value: ${metric.delta}` },
      (event) => {
        event.severity = 'info'
        event.context = 'web-vitals'
        event.addMetadata('metric', metric)
      },
    )
  }
}

export const setupWebVitals = (): void => {
  onCLS(report)
  onFID(report)
  onLCP(report)
}

import type { KeyFilter } from '@vueuse/core'
import { onKeyStroke } from '@vueuse/core'

const INPUT_TAG_NAMES = new Set(['INPUT', 'TEXTAREA'])

type Handler = (event: KeyboardEvent) => void

export const onKeyStrokeWithoutInput = (key: KeyFilter, handler: Handler): void => {
  onKeyStroke(key, (event) => {
    if (event.target instanceof HTMLElement && INPUT_TAG_NAMES.has(event.target.tagName)) {
      return
    }

    handler(event)
  })
}

import { useEventListener } from '@vueuse/core'
import type { DeepReadonly, Ref } from 'vue'

export const useComposing = (): DeepReadonly<Ref<boolean>> => {
  const isComposing = ref(false)

  // NOTE: KeyboardEvent.isComposingは2021年時点でsafariだけ動作が異なるという情報があるため
  useEventListener('compositionstart', () => {
    isComposing.value = true
  })
  useEventListener('compositionend', () => {
    isComposing.value = false
  })

  return readonly(isComposing)
}

<script lang="ts">
export type OnClick = () => void
</script>

<script lang="ts" setup>
const props = defineProps<{
  pinned: boolean
  onClick: OnClick
}>()
</script>

<template>
  <button class="toggle-pinned-project-button tw-group btn btn-light" @click.stop.prevent="props.onClick">
    <iconify-icon
      icon="bi:pin-fill"
      class="tw-text-xs"
      :class="props.pinned ? 'tw-text-gray-700' : 'tw-text-gray-300 group-hover:tw-text-gray-700'"
    />
  </button>
</template>

<style lang="scss" scoped>
.toggle-pinned-project-button {
  border-radius: 3px;
  line-height: 0;
  padding: 6px;
}
</style>

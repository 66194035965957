import { sortBy } from '@/src/lib/collections/sortBy'

type User = {
  id: string
  name: string
  lastSignInAt?: string | undefined
}

type UserWithLowPriority<T> = T & {
  lowPriority: boolean
}

const appendLowPriorityToUsers = <T extends User>(users: readonly T[]): ReadonlyArray<UserWithLowPriority<T>> => {
  const signInTimeThreshold = Date.now() - 86_400 * 30 * 1000

  return users.map((user) => ({
    ...user,
    lowPriority: user.lastSignInAt ? new Date(user.lastSignInAt).getTime() < signInTimeThreshold : true,
  }))
}

export const sortUsersByPriorityAndName = <T extends User>(
  users: readonly T[],
  currentUserId?: string,
): ReadonlyArray<UserWithLowPriority<T>> => {
  return sortBy(appendLowPriorityToUsers(users), (user) => {
    const prefix = user.id === currentUserId ? '0' : user.lowPriority ? '2' : '1'
    return `${prefix}${user.name}`
  })
}

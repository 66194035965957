/* eslint-disable unicorn/no-await-expression-member */
import { vwrap } from '@sonicgarden/vwrap'

import type { App } from 'vue'
import LoadingIcon from '@/src/basics/LoadingIcon.vue'
import UserIcon from '@/src/basics/UserIcon.vue'
import ProjectMenuContainer from '@/src/containers/ProjectMenuContainer.vue'
import SearchModalContainer from '@/src/containers/SearchModalContainer.vue'

const beforeMountCallback = (app: App) => {
  window.bugsnagClient?.getPlugin('vue')?.install(app)
}

export function initVue(): void {
  vwrap('x-projects-menu-container', ProjectMenuContainer, { beforeMountCallback })
  vwrap('x-loading-icon', LoadingIcon, { beforeMountCallback })
  vwrap('x-user-icon', UserIcon, { beforeMountCallback })
  vwrap('x-search-modal-container', SearchModalContainer, { beforeMountCallback })

  vwrap(
    'x-persist-notification-checkbox',
    async () => (await import('@/src/components/PersistNotificationCheckbox.vue')).default,
    { beforeMountCallback },
  )
  vwrap('x-copy-button', async () => (await import('@/src/components/CopyButton.vue')).default, { beforeMountCallback })
  vwrap('x-user-select', async () => (await import('@/src/components/UserSelect.vue')).default, { beforeMountCallback })
  vwrap('x-base-textarea', async () => (await import('@/src/components/BaseTextarea.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-html-content', async () => (await import('@/src/basics/HtmlContent.vue')).default, { beforeMountCallback })
  vwrap('x-blob-link', async () => (await import('@/src/components/BlobLink.vue')).default, { beforeMountCallback })
  vwrap('x-attachment-link', async () => (await import('@/src/components/AttachmentLink.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-author-header', async () => (await import('@/src/components/AuthorHeader.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-dropdown-menu', async () => (await import('@/src/components/DropdownMenu.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-project-dashboard', async () => (await import('@/src/pages/ProjectDashboard.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-project-messages-panes', async () => (await import('@/src/pages/ProjectMessagesPanes.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-single-message', async () => (await import('@/src/pages/SingleMessage.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-project-tasks-panes', async () => (await import('@/src/pages/ProjectTasksPanes.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-project-issues-panes', async () => (await import('@/src/pages/ProjectIssuesPanes.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-single-task', async () => (await import('@/src/pages/SingleTask.vue')).default, { beforeMountCallback })
  vwrap('x-single-issue', async () => (await import('@/src/pages/SingleIssue.vue')).default, { beforeMountCallback })
}

<script lang="ts">
export type OnInput = (query: string) => void
export type NavItem = 'message' | 'task' | 'attachment'
export type SearchResultItem =
  | TaskForSearchModalFragment
  | MessageForSearchModalFragment
  | AttachmentForSearchModalFragment
</script>

<script lang="ts" setup>
import { syncRef, useCycleList, useVModel } from '@vueuse/core'
import { BModal } from 'bootstrap-vue-next'
import BsFormInput from '@/src/basics/BsFormInput.vue'
import SearchModalAttachmentItem from '@/src/components/SearchModal/SearchModalAttachmentItem.vue'
import SearchModalMessageItem from '@/src/components/SearchModal/SearchModalMessageItem.vue'
import SearchModalNav from '@/src/components/SearchModal/SearchModalNav.vue'
import SearchModalScrollLIstGroupItem from '@/src/components/SearchModal/SearchModalScrollLIstGroupItem.vue'
import SearchModalTaskItem from '@/src/components/SearchModal/SearchModalTaskItem.vue'
import type {
  AttachmentForSearchModalFragment,
  MessageForSearchModalFragment,
  TaskForSearchModalFragment,
} from '@/src/graphql/generated'
import { useComposing } from '@/src/hooks/useComposing'
import { useKeyboardNavigationChildNodes } from '@/src/hooks/useKeyboardNavigationChildNodes'

type SearchResults = readonly SearchResultItem[]

const props = defineProps<{
  visible: boolean
  query: string
  activeNavItem: NavItem
  results: SearchResults
  loading: boolean
  moreResults: boolean
  searchable: boolean
  messageResultCount: number
  taskResultCount: number
  attachmentResultCount: number
}>()

const emit = defineEmits<{
  'update:visible': [visible: boolean]
  'update:query': [query: string]
  'update:activeNavItem': [activeNavItem: NavItem]
}>()

const inputRef = ref<InstanceType<typeof BsFormInput>>()
const listRef = ref<HTMLElement>()
const dirtyVisible = useVModel(props, 'visible', emit, { passive: true, eventName: 'update:visible' })
const dirtyQuery = useVModel(props, 'query', emit, { passive: true, eventName: 'update:query' })
const dirtyActiveNavItem = useVModel(props, 'activeNavItem', emit, {
  passive: true,
  eventName: 'update:activeNavItem',
})
const { index, onKeydown, reset } = useKeyboardNavigationChildNodes(listRef)
const isComposing = useComposing()
const { state, prev, next } = useCycleList<NavItem>(['message', 'task', 'attachment'], {
  initialValue: dirtyActiveNavItem.value,
})
syncRef(state, dirtyActiveNavItem)

watch(
  () => props.results,
  () => reset(),
)

const focus = () => {
  // NOTE: nextTickはBModal本体の挙動のfocusより後に処理する必要があるため
  // SEE: https://github.com/bootstrap-vue/bootstrap-vue-next/blob/9b7e2cf69bbeedc046cf12380a7d260c94fa38c6/packages/bootstrap-vue-3/src/components/BModal.vue#L275
  nextTick(() => {
    inputRef.value?.inputRef.focus()
  })
}
const handleKeyDownTab = (event: KeyboardEvent) => {
  if (isComposing.value) return

  event.preventDefault()
  event.shiftKey ? prev() : next()
}

// NOTE: もうちょいいい方法思いついたらテンプレート側と合わせて見直したい
const isTask = (item: SearchResultItem): item is TaskForSearchModalFragment => {
  return item.node.__typename === 'Task'
}
const isMessage = (item: SearchResultItem): item is MessageForSearchModalFragment => {
  return item.node.__typename === 'Message'
}
</script>

<template>
  <BModal v-model="dirtyVisible" size="lg" hide-header hide-footer @shown="focus">
    <div class="vstack gap-4">
      <form @submit.prevent>
        <BsFormInput
          ref="inputRef"
          v-model="dirtyQuery"
          type="search"
          size="lg"
          placeholder="例: キーワード1 キーワード2 -除外ワード。tabで検索タブ切替え。"
          @keydown="onKeydown"
          @keydown.tab="handleKeyDownTab"
        />
      </form>
      <SearchModalNav
        v-model="dirtyActiveNavItem"
        :show-count="searchable"
        :message-result-count="messageResultCount"
        :task-result-count="taskResultCount"
        :attachment-result-count="attachmentResultCount"
      />
      <div v-show="searchable">
        <LoadingIcon v-if="loading" class="tw-text-xl" is-center />
        <div v-else class="vstack gap-1">
          <ScrollLIstGroup v-show="results.length > 0" ref="listRef" class="tw-max-h-[60vh]" @keydown="onKeydown">
            <SearchModalScrollLIstGroupItem
              v-for="(result, i) of results"
              :key="result.node.id"
              :item="result"
              :active="index === i"
              class="position-relative tw-pr-11"
            >
              <SearchModalTaskItem v-if="isTask(result)" :task="result" />
              <SearchModalMessageItem v-else-if="isMessage(result)" :message="result" />
              <SearchModalAttachmentItem v-else :attachment="result" />
              <BsButton
                variant="light"
                size="sm"
                class="position-absolute tw-top-1/2 tw-right-4 tw-transform tw--translate-y-1/2 tw-opacity-0 tw-transition-opacity"
                :class="{
                  'tw-opacity-100': index === i,
                }"
              >
                <iconify-icon icon="bi:arrow-return-left" />
              </BsButton>
            </SearchModalScrollLIstGroupItem>
          </ScrollLIstGroup>
          <div v-if="results.length > 0" class="tw-text-xs text-body-secondary">
            <span v-if="moreResults">最新{{ results.length }}件の結果まで表示しています。</span>
            <span>「Ctrl」（Macの場合はCommand）キーを押しながらクリックすると別タブで開きます。</span>
          </div>
        </div>
      </div>
      <div v-show="!searchable" class="text-body-secondary">検索キーワードを2文字以上入力してください...</div>
    </div>
  </BModal>
</template>

<script lang="ts">
export type Project = {
  pinned: boolean
} & ProjectForProjectMenuFragment
</script>

<script lang="ts" setup>
import { gql } from '@apollo/client/core'
import { useMediaQuery } from '@vueuse/core'
import BsFormInput from '@/src/basics/BsFormInput.vue'
import type { ProjectForProjectMenuFragment } from '@/src/graphql/generated'
import { sortBy } from '@/src/lib/collections/sortBy'
import { projectPath, newProjectPath } from '@/src/lib/rails-routes'

gql`
  fragment projectForProjectMenu on Project {
    id
    projectType
    name
    iconThumbUrl
  }
`

const normalizeProjectName = (projectName: string): string => {
  return projectName.normalize('NFKC').toLowerCase()
}

type OnShown = () => void

type State = {
  projectQuery: string
}

const props = withDefaults(
  defineProps<{
    projects: readonly Project[]
    isDeveloper?: boolean
    loading: boolean
    onShown: OnShown
  }>(),
  {
    isDeveloper: false,
  },
)

const projectQueryInput = ref<InstanceType<typeof BsFormInput>>()

const state = reactive<State>({
  projectQuery: '',
})

const isLargeScreen = useMediaQuery('(min-width: 992px)')
const normalizedProjectQuery = computed<string>(() => normalizeProjectName(state.projectQuery))

const filteredProjects = computed<readonly Project[]>(() => {
  return props.projects.filter((project) => {
    return normalizeProjectName(project.name).includes(normalizedProjectQuery.value)
  })
})

const sortedProjects = computed<readonly Project[]>(() =>
  sortBy(filteredProjects.value, (project) => [project.pinned ? 0 : 1, project.name].join('')),
)

const handleShown = () => {
  if (isLargeScreen.value) {
    projectQueryInput.value?.inputRef.focus()
  }
  props.onShown()
}
</script>

<template>
  <li data-test-selector="projects-dropdown-menu">
    <BsDropdown
      variant="link"
      button-class="text-dark"
      menu-class="tw-scrollbar-thin tw-scrollbar-thumb-gray-500 tw-scrollbar-track-gray-100 tw-max-h-md tw-overflow-y-auto tw-w-64 sm:tw-w-xs"
      right
      @shown="handleShown"
    >
      <template #button-content>
        <iconify-icon icon="bi:list-stars" class="d-md-none" inline />
        <span class="d-none d-md-inline"> プロジェクト </span>
      </template>
      <li class="dropdown-item">
        <BsFormInput
          ref="projectQueryInput"
          v-model="state.projectQuery"
          type="search"
          placeholder="絞り込み"
          size="sm"
        />
      </li>
      <BsDropdownItem v-if="loading">
        <div class="d-flex justify-content-center py-4">
          <LoadingIcon></LoadingIcon>
        </div>
      </BsDropdownItem>
      <BsDropdownItem
        v-for="project in sortedProjects"
        :key="project.id"
        class="tw-leading-7"
        :href="projectPath({ id: project.id })"
      >
        <div class="d-flex align-items-center">
          <img :src="project.iconThumbUrl" class="tw-h-6 img-thumbnail" width="24" height="24" />
          <span class="tw-max-w-52 d-inline-block text-truncate ms-2">
            {{ project.name }}
          </span>
          <span
            v-if="isDeveloper"
            class="badge rounded-pill ms-2"
            :class="{
              'bg-info': project.projectType === 'VCTO',
              'bg-primary': project.projectType === 'GYOMU',
              'bg-secondary': project.projectType === 'PRIVATE',
            }"
          >
            {{ project.projectType }}
          </span>
          <TogglePinnedProjectButtonContainer class="ms-auto" :project="project" />
        </div>
      </BsDropdownItem>
      <template v-if="isDeveloper">
        <BsDropdownDivider />
        <BsDropdownItem :href="newProjectPath()">
          <iconify-icon icon="bi:plus-lg" inline />
          新規プロジェクト
        </BsDropdownItem>
      </template>
    </BsDropdown>
  </li>
</template>

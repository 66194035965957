import { debounce } from '@github/mini-throttle'

const getPosition = (value: string, position: number) => {
  const lines = value.slice(0, Math.max(0, position)).split('\n')
  const line = lines.length
  const column = (lines.at(-1)?.length ?? 0) + 1
  return {
    line,
    column,
  }
}

const formatMessage = (message: string, value: string) => {
  const position = message.match(/position (\d+)$/)?.[1]
  if (!position) return message

  const { line, column } = getPosition(value, Number.parseInt(position, 10))
  return `${message} (line: ${line}, column: ${column})`
}

export class JsonTextarea extends HTMLElement {
  connectedCallback(): void {
    this.addEventListener('input', this.debouncedOnInput)
  }

  disconnectedCallback(): void {
    this.removeEventListener('input', this.debouncedOnInput)
  }

  private onInput = () => {
    try {
      JSON.parse(this.textarea.value)
      this.textarea.setCustomValidity('')
    } catch (error) {
      if (error instanceof SyntaxError) {
        this.textarea.setCustomValidity(formatMessage(error.message, this.textarea.value))
      }
    }
  }

  private debouncedOnInput = debounce(this.onInput, 500)

  get textarea(): HTMLTextAreaElement {
    const textarea = this.querySelector('textarea')
    if (!textarea) throw new Error('Not found textarea')
    return textarea
  }
}

declare global {
  interface Window {
    JsonTextarea: typeof JsonTextarea
  }
}

if (!window.customElements.get('json-textarea')) {
  window.JsonTextarea = JsonTextarea
  window.customElements.define('json-textarea', JsonTextarea)
}

import type { MaybeElementRef } from '@vueuse/core'
import { unrefElement } from '@vueuse/core'
import type { Ref } from 'vue'
import { useKeyboardScrollNavigation, type OnKeydown, type Handler } from '@/src/hooks/useKeyboardScrollNavigation'
import { isMac } from '@/src/lib/browser'

export type UseKeyboardNavigationChildNodesReturn = {
  index: Ref<number>
  onKeydown: OnKeydown
  reset: () => void
}

export const useKeyboardNavigationChildNodes = (element: MaybeElementRef): UseKeyboardNavigationChildNodesReturn => {
  const index = ref(0)

  const findByIndex = (i: number): HTMLElement | undefined => {
    const target = unrefElement(element)?.children[i]
    return target && target instanceof HTMLElement ? target : undefined
  }

  const focusAt = (newIndex: number) => {
    const target = findByIndex(newIndex)
    if (!target) return

    const beforeActiveElement = window?.document.activeElement
    target.focus()

    // NOTE: input要素と組み合わせた場合の動作を考慮している
    if (beforeActiveElement instanceof HTMLElement && !unrefElement(element)?.contains(beforeActiveElement)) {
      beforeActiveElement.focus()
    }

    index.value = newIndex
  }

  const next: Handler = () => {
    const childCount = unrefElement(element)?.childElementCount ?? 0
    focusAt(Math.min(index.value + 1, childCount - 1))
  }

  const prev: Handler = () => {
    focusAt(Math.max(index.value - 1, 0))
  }

  const select: Handler = ({ ctrlKey, metaKey }) => {
    const target = findByIndex(index.value)
    const eventInitDict: MouseEventInit = isMac() ? { metaKey: ctrlKey || metaKey } : { ctrlKey }
    target?.dispatchEvent(new MouseEvent('click', eventInitDict))
  }

  const reset = () => {
    index.value = 0
  }

  const { onKeydown } = useKeyboardScrollNavigation({ next, prev, select })

  return { index, onKeydown, reset }
}

<script lang="ts">
import { gql } from '@apollo/client/core'

gql`
  fragment attachmentForSearchModal on SearchAttachmentResultEdge {
    highlightHtml(radius: 150)
    node {
      id
      url
      createdAt
      blob {
        id
        variantUrl(variant: FILL_240)
        filename
        byteSize
        contentType
      }
      attachable {
        id
        user {
          id
          name
          imageThumbUrl
        }
      }
    }
  }
`
</script>

<script lang="ts" setup>
import type { AttachmentForSearchModalFragment } from '@/src/graphql/generated'
import { humanFileSize } from '@/src/lib/numberUtils'

defineProps<{
  attachment: AttachmentForSearchModalFragment
}>()
</script>

<template>
  <div class="d-flex gap-2">
    <UserIcon :name="attachment.node.attachable.user.name" :url="attachment.node.attachable.user.imageThumbUrl" />
    <div class="vstack gap-1">
      <div class="vstack">
        <div class="hstack gap-3">
          <local-time :datetime="attachment.node.createdAt" class="text-body-secondary" />
        </div>
        <div v-if="!attachment.node.blob.variantUrl" class="d-flex align-items-baseline gap-2">
          <FileIcon :content-type="attachment.node.blob.contentType"></FileIcon>
          <span class="tw-text-sm fw-bold">{{ attachment.node.blob.filename }}</span>
          <span class="tw-text-xs text-body-secondary">{{ humanFileSize(attachment.node.blob.byteSize) }}</span>
        </div>
      </div>
      <img
        v-if="attachment.node.blob.variantUrl"
        :src="attachment.node.blob.variantUrl"
        alt=""
        width="240"
        height="240"
        loading="lazy"
        class="tw-max-w-32 tw-max-h-32"
      />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="text-body-secondary" v-html="attachment.highlightHtml"></div>
    </div>
  </div>
</template>

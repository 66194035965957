<script lang="ts" setup>
import { gql } from '@apollo/client/core'
import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable'
import { CurrentUserWithProjectsDocument } from '@/src/graphql/generated'
import { getApolloClient } from '@/src/lib/apollo'

gql`
  query currentUserWithProjects {
    currentUser {
      id
      isDeveloper
      memberships {
        id
        pinned
        project {
          ...projectForProjectMenu
        }
      }
    }
  }
`

provideApolloClient(getApolloClient())

const { result, load, loading } = useLazyQuery(CurrentUserWithProjectsDocument)
const isDeveloper = computed(() => result.value?.currentUser.isDeveloper ?? false)
const projects = computed(() => {
  return (
    result.value?.currentUser.memberships.map(({ pinned, project }) => {
      return {
        pinned,
        ...project,
      }
    }) ?? []
  )
})
</script>

<template>
  <ProjectsMenu :projects="projects" :is-developer="isDeveloper" :on-shown="load" :loading="loading" />
</template>

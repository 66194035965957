<script lang="ts" setup>
import type { Variant } from '@/src/types/bootstrap'

const props = defineProps<{
  href?: string
  target?: string
  active?: boolean
  disabled?: boolean
  variant?: Variant
}>()

const tag = computed<'button' | 'a'>(() => (props.href ? 'a' : 'button'))

defineOptions({
  inheritAttrs: false,
})
</script>

<template>
  <li role="presentation">
    <component
      :is="tag"
      :type="tag === 'button' ? 'button' : undefined"
      :aria-current="props.active ? 'true' : undefined"
      :href="href"
      class="dropdown-item"
      v-bind="$attrs"
      :class="[
        variant ? `text-${variant}` : undefined,
        {
          active: active,
          disabled: disabled,
        },
      ]"
    >
      <slot />
    </component>
  </li>
</template>

<script lang="ts">
import { gql } from '@apollo/client/core'

gql`
  fragment messageForSearchModal on SearchMessageResultEdge {
    highlightHtml(radius: 150)
    node {
      id
      createdAt
      content
      url
      isArchived
      user {
        id
        imageThumbUrl
        name
      }
    }
  }
`
</script>

<script lang="ts" setup>
import type { MessageForSearchModalFragment } from '@/src/graphql/generated'

defineProps<{
  message: MessageForSearchModalFragment
}>()
</script>

<template>
  <div class="d-flex gap-2">
    <UserIcon :name="message.node.user.name" :url="message.node.user.imageThumbUrl" />
    <div class="vstack gap-1">
      <div class="hstack gap-3">
        <local-time :datetime="message.node.createdAt" class="text-body-secondary" />
        <iconify-icon v-if="message.node.isArchived" title="アーカイブ済み" icon="bi:check-circle-fill" inline />
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="text-body-secondary" v-html="message.highlightHtml"></div>
    </div>
  </div>
</template>

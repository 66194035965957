type Blob = {
  variantUrl?: string
  filename: string
  contentType: string
}

const VIEWABLE_CONTENT_TYPES = new Set(['application/pdf', 'video/mp4'])

/** PDF・画像・動画については画面共有しやすいようにダウンロードではなくタブで開くための判定用 */
export const isDownloadable = (blob: Blob): boolean => !blob.variantUrl && !VIEWABLE_CONTENT_TYPES.has(blob.contentType)
